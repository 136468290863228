@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  body {
    @apply text-black;
  }
}

@layer components {
  .icon-wrapper {
    @apply flex items-center justify-center rounded-xl bg-whitish;
  }
  .flex-center {
    @apply flex items-center justify-center;
  }
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}
